<template>
    <div class="container">
    <div class="item" v-for="(item,i) in list" :key="i">
        <div class="img-box">
            <img v-lazy="item.summaryPicture">
        </div>
        <div class="text-box">
            <div class="title">{{item.title}}</div>
            <div class="text">{{item.summaryContent}}</div>
            <div class="date">{{item.createTime}} 发布</div>
            <div class="showDetail" ><span @click="toDetail(item.id)">查看详情</span></div>
        </div>
        <div class="line"></div>
    </div>
    <Loading v-if="Loading"></Loading>
    <div class="page">
        <div @click="prev">上一页</div>
        <p>{{pageCurrent}} / {{pageToatal}}</p>
        <div @click="next">下一页</div>
    </div>
  </div>
</template>

<script>
import Loading from '../components/Loading.vue'
export default {
    components:{Loading},
    data(){
        return{
            options:{
                pageSize: 5,
                pageIndex:1,
                title:'',
                type:1,
                state:1,
            },
            list:[],
            total:0,
            pageToatal:1,
            pageCurrent:1,
            Loading:false,
            idList:[],
            //用于缓存的数组
            cacheList:[],
        }
    },
    methods:{
        //获取文章列表
        getArticleSummary(options){
            this.Loading = true;
            this.list = [];
            this.$api.getArticleSummary(options).then(res=>{
                if(res.data.code == 200){
                    this.list = res.data.data;
                    this.total = res.data.total;
                    this.pageToatal = Math.ceil(this.total / this.options.pageSize)
                    this.Loading = false;
                    this.idList = [];
                    this.list.map(item=>{
                        this.idList.push(item.id)
                    })
                    if(this.cacheList.length == 0){
                        this.cacheList.push(this.list)
                    }else{
                        let flag = false;
                        this.cacheList.map(item=>{
                            if(this.cacheList[this.pageCurrent-1]){
                                flag = true
                            }
                        });
                        if(!flag){
                            this.cacheList.push(this.list)
                        }
                    }
                }
            })
        },
        //查看详情
        toDetail(id){
            this.$router.push('/detail?type=1&id='+id+'&idList='+JSON.stringify(this.idList))
        },
        //上一页
        prev(){
            if (this.options.pageIndex <= 1) return;
            this.options.pageIndex --;
            this.pageCurrent = this.options.pageIndex
            if(this.cacheList[this.pageCurrent-1]){
                this.list = this.cacheList[this.pageCurrent-1]
            }else{
                this.getArticleSummary(this.options);
            }
        },
        //下一页
        next(){
            if(this.pageCurrent >= this.pageToatal) return
            this.options.pageIndex ++;
            this.pageCurrent = this.options.pageIndex
            if(this.cacheList[this.pageCurrent-1]){
                this.list = this.cacheList[this.pageCurrent-1]
            }else{
                this.getArticleSummary(this.options);
            }
        },
        timestampToTime(timestamp) {
            var date = timestamp*1000;//时间戳为10位需*1000，时间戳为13位的话不需乘1000
            var Y = date.getFullYear() + '-';
            var M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + '-';
            var D = (date.getDate() < 10 ? '0'+date.getDate() : date.getDate()) + ' ';
            var h = (date.getHours() < 10 ? '0'+date.getHours() : date.getHours()) + ':';
            var m = (date.getMinutes() < 10 ? '0'+date.getMinutes() : date.getMinutes()) + ':';
            var s = (date.getSeconds() < 10 ? '0'+date.getSeconds() : date.getSeconds());
            var strDate = Y+M+D+h+m+s;
            return strDate;
        }
    },
    mounted(){
        this.getArticleSummary(this.options)
    },
    created(){
        document.body.style.height = "100%"
        document.documentElement.style.height = "100%"
    },
}
</script>

<style lang="scss" scoped>
.container{
    width: 1200px;
    margin: 0 auto;
    height: auto;
    min-height: 200px;
}
.item{
    margin: 40px auto;
    display: flex;
    justify-content: space-between;
    padding: 0 100px;
    position: relative;
}
.img-box{
    width: 500px;
    height: 320px;
    img{height: 100%;width: 100%;}
}
.text-box{
    width: 433px;
    height: 320px;
    .title{
        width: 100%;
        min-height: 50px;
        display: flex;
        justify-content: center; 
        align-items: center;
        font-size: 19px;
        font-weight: bold;
        margin-bottom: 10px;
    }
    .text{
        color: #666;
        letter-spacing: 1px;
        line-height: 28px;
    }
    .date{
        color: #808080;
        font-size: 15px;
        text-align: right;
        margin: 20px 0; 
    }
    .showDetail{
        color: #4D7BFF;
        font-size: 15px;
        font-weight: bold;
        letter-spacing: 1px;
        text-align: right;
        span{
            cursor: pointer;
        }
    }
}
.line{
    width: 1200px;
    border: 1px dashed #ccc;
    position: absolute;
    left: 0;
    bottom: -21px;
}
.page{
    height: 50px;
    color: #4D4D4D;
    font-size: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    //border: 1px solid red;
    margin-bottom: 15px;
    div{
        margin: 0 5px;
        cursor: pointer;
    }
    div:hover{
        color: #4D7BFF;
        font-weight: bold;
    }
}
</style>